// packages
import { useState, useEffect, useRef } from 'react';
import { Main, Container, Content, ContentNav, Header, Footer, Navbar } from '../../components/layout';
import { Carousel, Image, Modal, Tooltip, Table, HomeButton } from '../../components/utilities';
import { For, If } from '../../components/scripts';
import $ from 'jquery';
import homebg from '../assets/homebg.jpeg';

import { GiTeacher } from 'react-icons/gi';
import { HiDocumentText } from 'react-icons/hi';
import { RiUserSettingsLine } from 'react-icons/ri';
import styled from 'styled-components';

const HomeComp = ({ className }) => {
	return (
		<>
			<Main className={className}>
				<Header title="ITS" />

				<Container>
					<h1 id="home-heading" style={{}}>
						U.S. Army Tank-Automotive & Armaments Command
					</h1>
					<home className="d-flex justify-content-center align-items-center h-100" style={{ backgroundImage: `url(${homebg})` }}></home>
					<section class="home-btns">
						<HomeButton title="Training Library" desc="Internal & External" img={<GiTeacher size={200} />} link="/training-library" />
						<HomeButton title="Documentation" desc="Operation and Maintenance Manuals" img={<HiDocumentText size={200} />} link="#" />
						<HomeButton title="Interactive Procedures" desc="Operation and Maintenance" img={<RiUserSettingsLine size={200} />} link="#" />
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const Home = styled(HomeComp)`
	.home-btns {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		width: 100%;
		height: 86%;
		top: 8vh;
		margin: auto;
	}

	#home-heading {
		color: var(--secondary);
		position: absolute;
		left: 25%;
		top: 8vh;
		z-index: 10;
		font-weight: bold;
	}

	@media screen and (max-width: 480px) {
		.home-btns {
			display: block;
			top: 1vh;
		}

		#home-heading {
			display: none;
		}
	}
`;

export default Home;
