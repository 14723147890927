// packages
import { useState, useEffect, useRef } from 'react';
import { Main, Container, Content, ContentNav, Header, Footer, Navbar } from '../../components/layout';
import { Carousel, Image, Modal, Tooltip, Table, HomeButton } from '../../components/utilities';
import { For, If } from '../../components/scripts';
import $ from 'jquery';
import homebg from '../assets/homebg.jpeg';
import BAT from '../assets/BAT.jpeg';
import BMT from '../assets/BMT.jpg';
import BHT from '../assets/BHT.jpeg';

import { FaPlayCircle } from 'react-icons/fa';
import styled from 'styled-components';

const TrainingLibraryComp = ({ className }) => {
	const data = [
		{ name: 'Basic Mechanical Training', thumbnail: BMT },
		{ name: 'Basic Hydraulic Training', thumbnail: BHT },
		{ name: 'Basic Automation Training', thumbnail: BAT },
		{ name: 'Basic Mechanical Training', thumbnail: BMT },
		{ name: 'Basic Hydraulic Training', thumbnail: BHT },
		{ name: 'Basic Automation Training', thumbnail: BAT },
	];
	return (
		<>
			<Main className={className}>
				<Header title="ITS" />

				<Container>
					<home className="d-flex justify-content-center align-items-center h-100" style={{ backgroundImage: `url(${homebg})` }}></home>
					<ContentNav crumbs={['Training Library']} />
					<section className="training-library">
						{data.map((video) => {
							return (
								<div className="video">
									<a href="/training-library/basic-mechanical-training">
										<div className="video-overlay">
											<FaPlayCircle size={150} />
										</div>

										<img src={video.thumbnail} />
										<h1>{video.name}</h1>
									</a>
								</div>
							);
						})}
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const TrainingLibrary = styled(TrainingLibraryComp)`
	.training-library {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 5vw 2vw;
		position: absolute;
		background: var(--primary);
		width: 96vw;
		height: auto;
		min-height: 65vh;
		top: 0;
		border-radius: 1rem;
	}

	.video {
		position: relative;
		margin: 15px;
		height: 38vh;
		width: 28vw;
		border-radius: 1rem;
		box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);

		& h1 {
			position: relative;
			width: 100%;
			color: #fff;
			text-align: center;
			background-color: #231f20;
			padding: 5px;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			border: solid var(--secondary) 5px;
			font-weight: bold;
			bottom: 19%;
		}

		& a {
			text-decoration: none;
			display: block;
			height: 100%;
		}

		& img {
			width: 100%;
			height: 100%;
			border-radius: 1rem;
		}

		& svg {
			z-index: 10;
			position: absolute;
			/* top: 20%;
			left: 38%; */
			color: #fff;
			filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
			margin-bottom: 2.5vw;

			&:hover {
				color: var(--secondary);
			}
		}

		&:hover {
			transform: scale(1.01);
		}
	}

	.video-overlay {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		position: absolute;
		height: 100%;
		background: transparent;
	}

	@media screen and (max-width: 480px) {
		.training-library {
			display: block;
			margin: 5vw 0;
		}

		.video {
			width: 80vw;
			height: 20vh;
			margin: 0 auto;
			margin-bottom: 72px;
		}

		.video-overlay svg {
			width: 24vw;
		}
	}
`;

export default TrainingLibrary;
