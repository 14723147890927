import React from 'react';
import styled from 'styled-components';

const HomeButtonComp = ({ className, title, img, desc, link }) => {
	return (
		<a href={link} style={{ textDecoration: 'none' }}>
			<button className={className}>
				{img}
				<h3>
					{title}
					<br />
					<p class="btn-desc">{desc}</p>
				</h3>
			</button>
		</a>
	);
};

const HomeButton = styled(HomeButtonComp)`
	z-index: 4;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: end;
	width: 24vw;
	height: 32vh;
	padding: 0;
	border-radius: 1rem;
	transition: all 0.3s ease-in-out;
	background: var(--secondary);
	margin: 20px;

	& svg {
		color: #fff;
		display: block;
		margin: auto;
	}

	.btn-desc {
		font-size: 22px;
		font-weight: 400;
		margin: 0;
	}

	& h3 {
		margin: 0 auto;
		background: white;
		width: 100%;
		padding: 10px;
		border-bottom-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
		color: #000;
		font-weight: bold;
		font-size: 30px;
	}

	& img {
		width: 100%;
		border-top-left-radius: 1rem;
		border-top-right-radius: 1rem;
	}

	&:hover {
		transform: scale(1.01);
	}

	@media screen and (max-width: 480px) {
		width: 80vw;
		margin: 20px auto;
	}
`;

export default HomeButton;
