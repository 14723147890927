import { Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import TrainingLibrary from './pages/TrainingLibrary';
import BMT from './pages/TrainingLibrary/BMT';

const Router = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/training-library" element={<TrainingLibrary />} />
			<Route path="/training-library/basic-mechanical-training" element={<BMT />} />
		</Routes>
	);
};

export default Router;
