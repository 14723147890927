// packages
import { useState, useEffect, useRef } from 'react';
import { Main, Container, Content, ContentNav, Header, Footer, Navbar } from '../../components/layout';
import { Carousel, Image, Modal, Tooltip, Table, HomeButton } from '../../components/utilities';
import { For, If } from '../../components/scripts';
import $ from 'jquery';
import homebg from '../assets/homebg.jpeg';
import BAT from '../assets/BAT.jpeg';
import BMTimg from '../assets/BMT.jpg';
import BHT from '../assets/BHT.jpeg';

import { FaPlayCircle } from 'react-icons/fa';
import styled from 'styled-components';

const BMTComp = ({ className }) => {
	return (
		<>
			<Main className={className}>
				<Header title="ITS" />

				<Container>
					<home className="d-flex justify-content-center align-items-center h-100" style={{ backgroundImage: `url(${homebg})` }}></home>
					<ContentNav crumbs={['Training Library', 'Basic Mechanical Training']} />
					<section className="training-library" style={{ background: 'var(--primary)' }}>
						<left>
							<div className="training-info-head">
								<h1>Basic Mechanical Training</h1>
								<h4>Time: 2 Hours</h4>
							</div>

							<div className="training-info">
								<h2>LOREM IPSUM DOLOR</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>

								<h2>EGESTAS QUIS IPSUM</h2>
								<p>Consectetur purus ut faucibus pulvinar elementum integer enim. Maecenas accumsan lacus vel facilisis volutpat est velit egestas dui. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat est. Mus mauris vitae ultricies leo integer malesuada nunc vel risus.</p>
							</div>
						</left>
						<right>
							<div className="video clicked">
								<FaPlayCircle size={150} />
								<img src={BMTimg} style={{ height: 'auto' }} />
							</div>
						</right>
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const BMT = styled(BMTComp)`
	left {
		margin: 1vw;
		width: 30vw;

		height: 65vh;

		& h1,
		h2,
		h4 {
			text-align: center;
			font-weight: bold;
		}

		& h2 {
			border-bottom: solid darkblue 2px;
			width: 75%;
			margin: 10px auto;
		}

		& p {
			margin: 15px 40px;
		}
	}

	.training-info-head {
		background: var(--secondary);
		margin-bottom: 20px;
		border-radius: 1rem;
	}

	.training-info {
		background: #fff;
		color: darkblue;
		font-weight: bold;
		padding: 10px;
		border-radius: 1rem;
	}
	right {
		margin: 1vw;
	}
	.training-library {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin: 5vw 2vw;
		position: absolute;
		background: #fff;
		width: 96vw;
		height: auto;
		min-height: 65vh;
		top: 0;
		border-radius: 1rem;
	}

	.video {
		position: relative;
		height: auto;
		width: 60vw;
		border-radius: 1rem;
		box-shadow: 6px 3px 16px -3px rgba(0, 0, 0, 0.75);

		& h1 {
			position: relative;
			width: 100%;
			color: #fff;
			text-align: center;
			background-color: #231f20;
			padding: 5px;
			border-bottom-left-radius: 1rem;
			border-bottom-right-radius: 1rem;
			border: solid var(--secondary) 5px;
			font-weight: bold;
			bottom: 19%;
		}

		& a {
			text-decoration: none;
			width: 100%;
		}

		& img {
			width: 100%;
			height: auto;
			border-radius: 1rem;
		}

		& svg {
			z-index: 10;
			position: absolute;
			color: #fff;
			filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));

			&:hover {
				color: var(--secondary);
			}
		}
	}

	.video.clicked {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	@media screen and (max-width: 480px) {
		.training-library {
			display: block;
		}

		.video {
			width: 95vw;
		}
	}
`;

export default BMT;
